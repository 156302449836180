import React, { useEffect } from 'react';
import { isNamedExportBindings } from 'typescript';

function log_out(): Promise<boolean> {
    return fetch("/api/pub/logout", {
        method: 'POST'
    }).then(() => true, () => false);
}

function login(password: string): Promise<boolean> {
    return fetch("/api/pub/login", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json;charset=utf-8'
        },
        body: JSON.stringify({ password: password })
    })
        .then(
            (data) => {
                return data.status == 200
            },
            (error) => {
                return false
            }
        )
}

export function auth(): Promise<string | null> {
    return fetch("/api/pub/login")
        .then(res => res.json())
        .then(
            (data) => {
                return data.login_type
            },
            (error) => {
                return null
            }
        )
}

const AuthContext = React.createContext<{ isAdmin: any, token: any, onLogin: any, onLogout: any } | null>(null);

export const useAuth = () => {
    return React.useContext(AuthContext);
};

export const AuthProvider = ({ children }: any) => {
    const [token, setToken] = React.useState<string | null>("user");
    const [actsAsUser, setActsAsUser] = React.useState(false);

    const handleLogin = async () => {
        const token = await auth();

        setToken(token);
    };

    const handleLogout = async () => {
        const logged_out = await log_out();
        setToken(null);
    };

    const isAdmin = () => {
        return token == "admin" && !actsAsUser;
    }

    const toggleAdmin = () => {
        if (token == "admin") {
            setActsAsUser(!actsAsUser);
        }
    }

    const value = {
        token,
        onLogin: handleLogin,
        onLogout: handleLogout,
        doLogin: login,
        isAdmin: isAdmin,
        toggleAdmin: toggleAdmin,
    };

    useEffect(() => {
        handleLogin();
    })

    return (
        <AuthContext.Provider value={value}>
            {children}
        </AuthContext.Provider>
    );
};