import logo from './logo.png';
import './App.css';
import Event, { parseEvent, IEvent } from './Event';
import { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';

interface EventsProps {
  admin: boolean
}

const classifyEvent = (event: IEvent) => {
  const now = new Date();
  let diff = now.getTime() - event.event_start_time.getTime();

  if (event.event_start_time > now && event.status == "planned") {
    return "planned";
  } else if (event.signup_opens_at < now && event.signup_closes_at > now) {
    return "signup";
  } else if (event.event_start_time > now) {
    return "upcoming";
  } else  if (diff > (1000 * 60 * 60 * 24 * 180)) {
    return "old";
  } else {
    return "past";
  }
}

function Section({ events, title, sectionClass }: any) {
  if (events.length > 0) {
    return (
      <div className="event-section">
        <h2 className="event-section-title">{title}</h2>
        <div className={"card-deck events " + sectionClass}>
          {events.map((event: IEvent) => (
            <Event isAdmin={false} key={event.id} event={event} mode="summary" onCapacityUpdated={(id, cap) => null}>
              <Button href={"/events/" + event.id} variant="outline-success">Details</Button>
            </Event>
          ))}
        </div>
      </div>
    )
  } else {
    return (<div></div>);
  }
}

function Events({ admin }: EventsProps) {
  const [error, setError] = useState(null);
  const [events, setEvents] = useState<Array<IEvent>>([]);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    fetch("/api/pub/events")
      .then(res => res.json())
      .then(
        (data) => {
          setIsLoaded(true);
          setEvents(data.map(parseEvent));
        },
        (error) => {
          setError(error);
          setIsLoaded(true);
        }
      )
  }, [])

  if (error) {
    return (
      <div className="content">
        <div className="error">Error Loading Events</div>
      </div>
    )
  } else if (!isLoaded) {
    return (
      <div className="content">
        <div className="loading">Loading Events...</div>
      </div>
    )
  } else {
    const planned = events.filter(event => classifyEvent(event) == 'planned');
    const signup = events.filter(event => classifyEvent(event) == 'signup');
    const upcoming = events.filter(event => classifyEvent(event) == 'upcoming');
    const past = events.filter(event => classifyEvent(event) == 'past');

    return (
      <div className="content">
        <Section events={planned} title="Boats Set" sectionClass="big-event" />
        <Section events={signup} title="Signup Open" sectionClass="big-event" />
        <Section events={upcoming} title="Upcoming" sectionClass="small-event" />
        <Section events={past} title="Past Events" sectionClass="small-event" />
      </div>
    );
  }
}

export default Events;