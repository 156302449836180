interface MailLinkProps {
    to: Array<string>, 
    bcc: Array<string>, 
    subj: string,
    children: any
}

export const defaultTo = ["hinz.adam@gmail.com", "danielcmaloney@gmail.com"];

export default function MailLink({ to, bcc, subj, children}: MailLinkProps) {
    let link = "mailto:";
    if (to && to.length > 0) {
        link += to.join(",");
        link += "?";
    }

    if (bcc && bcc.length > 0) {
        link += "&bcc=" + bcc.join(",");
    }

    if (subj) {
        link += "&subject=" + encodeURIComponent(subj);
    }

    return (
        <a target="_blank" href={link}>
            {children}
        </a>
    )
}