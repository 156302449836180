function SummaryColumbia() {
    return (
        <div>In-shore Beer Can series hosted by Columbia! Meet at Columbia before the race and join us after for beer, food, and racing debrief.</div>
    )
}

function SummaryMonroeRegular() {
    return (
        <div>
            We'll be kicking off the season with a practice race hosted by Columbia.
        </div>
    )
}

function SummaryMonroeCrib() {
    return (
        <div>No Columbia racing this week! We'll be meeting at Monroe and doing a distance race around the cribs. The course will be determined based on the weather before racing on Wednesday</div>
    )
}

export function Summary({ eventType, desc }: Props) {
    if (eventType == "WednesdayRaceColumbia") {
        return (<SummaryColumbia />)
    } else if (eventType == "WednesdayRaceMonroe") {
        // todo: preseason + crib race
        return (<SummaryMonroeRegular />)
    }  else if (eventType == "WednesdayRaceMonroeCrib") {
        // todo: preseason + crib race
        return (<SummaryMonroeCrib />)
    } else {
        return (<div>{desc}| {eventType}</div>)
    }
}

function SharedDetail({ loc }: {loc: String}) {
    return (
        <div>
            <p>
                Boats are assigned automatically based on skill level.
            </p>

            <h4>Sign Up</h4>
            <p>Sign up for this event is done through this website</p>

            <h4>Wait List</h4>
            <p>
                If more people have signed up than we have spots there will be a waitlist on the website.
                If there is a waitlist and you're assigned to a boat and can no longer come please email
                the racing coordinators as soon as possible so we can pull someone from the waitlist.
            </p>

            <h4>Event Times</h4>
            <p>
                Please try to be at {loc} by 5:30-5:45. If you're going to be late please text
                Adam (608-445-8391) and Dan (773-456-8411) and meet us at the boats.
            </p>
            <p>
                The boats leave the mooring at 6:15 (latest) so if your tender arrives after that the
                boat won't be there.
            </p>

            <h4>Guests</h4>
            <p>
                Sail Chicago racing is open only to members that have paid the $100 racing fee. If you're
                already a member of Sail Chicago and are curious about racing please let Dan or Adam know
                and we'll do our best to answer your questions.
            </p>

            <h4>What to bring</h4>
            <p>
                Dress for the weather.  Err on the side of dressing warmer than you think you'll need.
            </p>
        </div>
    )
}

function DetailColumbia() {
    return (
        <div>
            <p>
                Join us for pick-up racing at with Columbia every Wednesday night.
                Open to all members of sail chicago that have signed up for the racing program.
                All racers must have experience equal to the equivalent of the "Crew" class.
            </p>

            <SharedDetail loc="Columbia" />

            <h4>Afterparty</h4>
            <p>Join us at Columbia for beer and food!</p>
        </div>
    )
}

function DetailMonroeRegular() {
    return (
        <div>
            <p>
                Meeting at Monroe Tender dock (access code: 44113#). Columbia will be hosting the race
                with a normal course.                
            </p>
            <p>
                Open to all members of sail chicago that have signed up for the racing program.
                All racers must have experience equal to the equivalent of the "Crew" class.
            </p>

            <SharedDetail loc="the Monroe Tender Dock" />

            <h4>Afterparty</h4>
            <p>After racing we'll either meet at a bar or debrief on the lawn outside of Monroe</p>
        </div>
    )
}

interface Props {
    eventType: string,
    desc: string
}

export function Detail({ eventType, desc }: Props) {
    if (eventType == "WednesdayRaceColumbia") {
        return (<DetailColumbia />)
    } else if (eventType == "WednesdayRaceMonroe") {
        // todo: preseason + crib race
        return (<DetailMonroeRegular />)
    }  else if (eventType == "WednesdayRaceMonroeCrib") {
        // todo: preseason + crib race
        return (<DetailMonroeRegular />)
    } else {
        return (<div>{desc}</div>)
    }
}