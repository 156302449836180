export interface User {
    id: number,
    sc_id: string,
    full_name: string,
    phone: string,
    racing_skipper: boolean,
    foredeck_cert: boolean,
    skipper_priority: number,
    foredeck_priority: number,
    skipper: boolean,
    racer: boolean,
    email: string,
    contacted: boolean
    deleted: boolean
};

const allRacers = () => {
    return fetch("/api/admin/racers")
        .then(res => res.json());
};

export const UserService = {
    allRacers
}
