import Boat from './Boat';
import Event, { IEvent, parseEvent } from './Event';
import { Card, Button, Modal } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { useState, useEffect, Fragment } from 'react';
import SignupModal from './SignupModal';
import UserList from './UserList';
import { useAuth } from './services/AuthService';
import { User } from './services/UserService';
import { BoatIdAndName, EventService } from './services/EventService';
import { EventReservation, SignupEventReservation, EventWithDetails, Signup } from './services/EventService';
import ParkingList from './components/ParkingList';
import MailLink, { defaultTo } from './components/Email';

interface EventDetailsProps {
    admin: boolean
}

function addSignupEventReservation(er: EventReservation, signup: Signup) {
    return fetch(
        "/api/admin/events/" + er.event_id +
        "/reservations/" + er.id +
        "/signup/" + signup.id +
        "/role/crew", {
        method: 'POST',
    });
}


function deleteSignupEventReservation(er: EventReservation, signup_res: SignupEventReservation) {
    return fetch(
        "/api/admin/events/" + er.event_id +
        "/reservations/" + er.id +
        "/signup/" + signup_res.signup.id, {
        method: 'DELETE',
    });
}

function updateSignupEventReservationRole(er: EventReservation, signup_res: SignupEventReservation, role: string) {
    return fetch(
        "/api/admin/events/" + er.event_id +
        "/reservations/" + er.id +
        "/signup/" + signup_res.signup.id +
        "/role/" + role, {
        method: 'PUT',
    });
}

function updateSignupEventReservationBoat(er: EventReservation, signup_res: SignupEventReservation, boat: string) {
    return fetch(
        "/api/admin/events/" + er.event_id +
        "/reservations/" + er.id +
        "/signup/" + signup_res.signup.id +
        "/boat/" + boat, {
        method: 'PUT',
    });
}

function syncEvent(eventId: string | undefined) {
    return fetch("/api/admin/events/" + eventId + "/sync", {
        method: 'POST'
    }).then(() => true, () => false);
}

function Waitlist({ handleWithdrawComplete, event, onSignup, signups, boats, isAdmin, onBoatSelected, editing }: any) {
    return (
        <Card className="waitlist">
            <Card.Body>
                <Card.Title>
                    Waitlist
                    {editing && <Button onClick={onSignup} variant="outline-success">Sign Up</Button>}
                </Card.Title>
                <span>
                    If you'd like to remove yourself from the waitlist or join racing please email the racing
                    coordinators directly.
                </span>
                {signups.length == 0 && <div>Nobody is on the waitlist!</div>}
                <UserList
                    onBoatSelected={onBoatSelected}
                    boats={boats}
                    isAdmin={isAdmin}
                    handleWithdrawComplete={handleWithdrawComplete}
                    event={event}
                    signups={signups}
                    editing={editing} />
            </Card.Body>
        </Card>
    )
}

function Signups({ handleWithdrawComplete, event, showSignup, signups, onSignup, boats, isAdmin, onBoatSelected, editing }: any) {
    let capacitySignups = signups.slice(0, event.capacity);
    let waitlistSignups = signups.slice(event.capacity);

    return (
        <Card className="waitlist">
            <Card.Body>
                <Card.Title>
                    Signups
                    {editing && <Button onClick={onSignup} variant="outline-success">Sign Up</Button>}
                </Card.Title>
                {signups.length == 0 && <div>Nobody is signed up yet!</div>}
                <UserList
                    onBoatSelected={onBoatSelected}
                    boats={boats}
                    isAdmin={isAdmin}
                    handleWithdrawComplete={handleWithdrawComplete}
                    event={event}
                    signups={capacitySignups}
                    editing={editing} />
                {waitlistSignups.length > 0 && <Card.Title>Waitlist</Card.Title>}
                {waitlistSignups.length > 0 && <div className="compact-detail">Waitlist entries will be processed in order and we'll send you an email if you're assigned to a boat!</div>}
                <UserList
                    onBoatSelected={onBoatSelected}
                    boats={boats}
                    isAdmin={isAdmin}
                    handleWithdrawComplete={handleWithdrawComplete}
                    event={event}
                    signups={waitlistSignups}
                    editing={editing} />
            </Card.Body>
        </Card>
    )
}

function EmailLinks({ details }: { details: EventWithDetails }) {
    const allSignups = details.signups.map(s => s.user.email);
    const allRacers: Array<string> = [];
    const skippers: Array<string> = [];
    details.event_reservations.forEach(er => {
        er.signups.forEach(s => {
            if (s.roleType === "Skipper") {
                skippers.push(s.signup.user.email);
            }
            allRacers.push(s.signup.user.email);
        });
    });
    const waitlist = details.waitlist.map(s => s.user.email);


    return (
        <Fragment>
            <MailLink to={defaultTo} bcc={allSignups} subj="[Sail Chicago Racing] ">
                Email All Signups
            </MailLink>
            <MailLink to={defaultTo} bcc={allRacers} subj="[Sail Chicago Racing] ">
                Email Assigned Racers
            </MailLink>
            <MailLink to={defaultTo} bcc={waitlist} subj="[Sail Chicago Racing] ">
                Email Waitlist Only
            </MailLink>
            <MailLink to={defaultTo} bcc={skippers} subj="[Sail Chicago Racing] ">
                Email Skippers
            </MailLink>
        </Fragment>
    )
}

function StatusSelect({ status, onChange }: { status: string, onChange: (status: string) => void }) {
    return (
        <div> Status:
            <select value={status} onChange={(e: any) => onChange(e.target.value)}>
                <option value="start">Unstarted (signup phase)</option>
                <option value="planned">Planned (assignments visible)</option>
                <option value="cancelled">Cancelled</option>
            </select>
        </div>
    )
}

function EventDetails({ admin }: EventDetailsProps) {
    const { id } = useParams();
    const { isAdmin } = useAuth() as any;

    const [error, setError] = useState(null);
    const [eventDetails, setEventDetails] = useState<EventWithDetails | null>(null);
    const [isLoaded, setIsLoaded] = useState(false);

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const loadEvent = () => {
        EventService.eventDetails(id)
            .then(
                (data) => {
                    setIsLoaded(true);
                    setEventDetails(data);
                },
                (error) => {
                    setIsLoaded(true);
                    setError(error);
                }
            )
    };

    const onBoatSelected = (event: IEvent, signup: Signup, boat: string) => {
        let er = eventDetails?.event_reservations.filter(er => er.boat == boat)[0] as EventReservation;

        return addSignupEventReservation(er, signup).then(() => loadEvent());
    };

    const onDeleteEventRes = (res: EventReservation, signup_res: SignupEventReservation) => {
        return deleteSignupEventReservation(res, signup_res).then(() => loadEvent());
    };

    const onRoleSelected = (res: EventReservation, signup_res: SignupEventReservation, role: string) => {
        return updateSignupEventReservationRole(res, signup_res, role).then(() => loadEvent());
    };

    const onBoatSelectedForSER = (res: EventReservation, signup_res: SignupEventReservation, boat: string) => {
        let new_er = eventDetails?.event_reservations.filter(er => er.boat == boat)[0] as EventReservation;

        return deleteSignupEventReservation(res, signup_res)
            .then(() => addSignupEventReservation(new_er, signup_res.signup))
            .then(() => loadEvent());
    };

    const handleSync = () => {
        return syncEvent(id).then(() => {
            return loadEvent()
        });
    };

    const handleSetBoats = () => {
        return fetch("/api/admin/events/" + id + "/assign", {
            method: 'POST'
        }).then(() => true, () => false)
            .then(() => loadEvent());
    }

    const handleDelete = () => {

    };

    const onCapacityUpdated = (eventId: number, capacity: number) => {
        return EventService.updateEvent(eventId, { capacity: capacity }).then(() => loadEvent());
    }

    const onStatusUpdated = (status: string) => {
        return EventService.updateEvent(id, { status: status }).then(() => loadEvent());
    }

    const onParkingUpdated = (signupId: number, oldBoat: string | null, newBoat: string | null) => {
        if (oldBoat != newBoat) {
            if (oldBoat && newBoat) {
                EventService.updateParking(id, signupId, newBoat).then(() => loadEvent());
            } else if (!oldBoat && newBoat) {
                EventService.createParking(id, signupId, newBoat).then(() => loadEvent());
            } else if (oldBoat && !newBoat) {
                EventService.deleteParking(id, signupId).then(() => loadEvent());
            }
        }
    };

    useEffect(loadEvent, []);

    if (error || (isLoaded && !eventDetails)) {
        return (
            <div className="content">
                <div className="error">Error Loading Events</div>
            </div>
        )
    } else if (!isLoaded) {
        return (
            <div className="content">
                <div className="loading">Loading Events...</div>
            </div>
        )
    } else {
        const details = eventDetails as EventWithDetails;
        const event = details.event;
        const admin = isAdmin();

        let showSignup = isAdmin();
        let now = new Date();
        let signupDisabled = now >= event.signup_closes_at;

        if (now >= event.signup_opens_at) {
            showSignup = true;
        }

        const boatsSet = event.status == "planned" || isAdmin();
        const boats = eventDetails?.event_reservations.map(er => er.boat).filter(x => x);

        return (
            <div className="content">
                <SignupModal show={show} handleClose={handleClose} eventId={event.id} handleSignupComplete={loadEvent} />
                <div className={"event-and-signups " + (isAdmin() && "admin-event" || "")}>
                    <Event event={event} mode="full" isAdmin={isAdmin()} onCapacityUpdated={onCapacityUpdated}>
                        {showSignup && <Button onClick={handleShow} variant="outline-success">Sign Up</Button>}
                        {isAdmin() && <Button onClick={handleSetBoats} variant="outline-success">Assign Boats</Button>}
                        {isAdmin() && <Button onClick={handleSync} variant="outline-warning">Sync</Button>}
                        {isAdmin() && <Button onClick={handleDelete} variant="outline-danger">Delete</Button>}
                        {isAdmin() && <EmailLinks details={details} />}
                        {isAdmin() && <StatusSelect status={event.status} onChange={onStatusUpdated} />}
                    </Event>
                    {!boatsSet && showSignup && <Signups
                        onBoatSelected={onBoatSelected}
                        boats={boats}
                        isAdmin={isAdmin()}
                        event={event}
                        signups={details.signups}
                        onSignup={handleShow}
                        showSignup={showSignup}
                        handleWithdrawComplete={loadEvent}
                        editing={!signupDisabled || isAdmin()} />}
                    {boatsSet && <Waitlist
                        onBoatSelected={onBoatSelected}
                        boats={boats}
                        isAdmin={isAdmin()}
                        event={event}
                        signups={details.waitlist}
                        onSignup={handleShow}
                        showSignup={showSignup}
                        handleWithdrawComplete={loadEvent}
                        editing={isAdmin()} />}
                </div>
                <div className="boats-and-waitlist">
                    <div className="boats">
                        {boatsSet && details.event_reservations.filter(res => res.boat).map(res => (
                            <Boat
                                key={res.id}
                                boats={boats as Array<string>}
                                onBoatSelected={onBoatSelectedForSER}
                                reservation={res}
                                isAdmin={isAdmin()}
                                onDeleteEventRes={onDeleteEventRes}
                                onRoleSelected={onRoleSelected}
                            />
                        ))}
                        {boatsSet && admin && details.event_reservations.filter(res => !res.boat).map(res => (
                            <Boat
                                key={res.id}
                                boats={boats as Array<string>}
                                onBoatSelected={onBoatSelectedForSER}
                                reservation={res}
                                isAdmin={isAdmin()}
                                onDeleteEventRes={onDeleteEventRes}
                                onRoleSelected={onRoleSelected}
                            />
                        ))}
                        {(boatsSet || admin) && (<ParkingList
                            onParkingUpdated={onParkingUpdated}
                            isAdmin={isAdmin()}
                            signups={details.signups}
                            boats={boats as Array<string>}
                        />)}
                    </div>
                </div>
            </div>
        )
    }
}

export default EventDetails;