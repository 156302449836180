import { UserService, User } from '../services/UserService';
import { Button } from 'react-bootstrap';
import React from 'react';
import MailLink, { defaultTo } from './Email';

function Toggle({ isSet }: { isSet: boolean }) {
    return (
        <Button>{isSet && "Yes"}{isSet || "No"}</Button>
    )
}

type PriorityCallback = (priority: string) => void;
interface PrioritySelectProps {
    priority: number | null,
    onPriorityUpdated: PriorityCallback
}

function PrioritySelect({ priority, onPriorityUpdated }: PrioritySelectProps) {
    return (
        <select value={priority + ""} onChange={(event: any) => onPriorityUpdated(event.target.value)}>
            <option key="null" value="null">none</option>
            <option key="0">0</option>
            <option key="1">1</option>
            <option key="2">2</option>
            <option key="3">3</option>
            <option key="4">4</option>
            <option key="5">5</option>
        </select>
    )
}

function UserTableRow({ user }: { user: User }) {
    return (
        <React.Fragment>
            <tr key={user.id}>
                <td>{user.full_name}</td>
                <td><a href={"mailto:" + user.email}>{user.email}</a></td>
                <td><a href={"tel:" + user.phone}>{user.phone}</a></td>
            </tr>
            <tr key={user.id + "-actions-foredeck"} className="action-row">
                <td colSpan={3}>Foredeck?&nbsp;
                    <Toggle isSet={user.foredeck_cert} />&nbsp;
                    <PrioritySelect priority={user.foredeck_priority} onPriorityUpdated={(p) => { }} />
                    &nbsp;&nbsp;&nbsp;
                    Skipper?&nbsp;
                    <Toggle isSet={user.racing_skipper} />&nbsp;
                    <PrioritySelect priority={user.skipper_priority} onPriorityUpdated={(p) => { }} />
                </td>
            </tr>
        </React.Fragment>
    )
}

function UserTable({ users }: { users: Array<User> }) {
    return (
        <table className="table table-sm">
            <tbody>
                {users.map(user => (
                    <UserTableRow user={user} />
                ))}
            </tbody>
        </table>
    )
}

export default function Directory() {
    const [error, setError] = React.useState(null);
    const [users, setUsers] = React.useState<Array<User>>([]);
    const [isLoaded, setIsLoaded] = React.useState(false);

    React.useEffect(() => {
        UserService.allRacers()
            .then(
                (users: Array<User>) => {
                    setIsLoaded(true);
                    setUsers(users);
                },
                (error: any) => {
                    setError(error);
                    setIsLoaded(true);
                }
            )
    }, []);

    if (error) {
        return (
            <div className="content">
                <div className="error">Error Loading Events</div>
            </div>
        )
    } else if (!isLoaded) {
        return (
            <div className="content">
                <div className="loading">Loading Events...</div>
            </div>
        )
    } else {
        return (
            <div className="content">
                <div>
                    <MailLink to={defaultTo} bcc={users.map(u => u.email)} subj="[Sail Chicago Racing] ">
                        Email all Racers
                    </MailLink>
                </div>
                <UserTable users={users} />
            </div>
        )
    }
}