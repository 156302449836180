import { User } from './UserService';
import dateFnsParse from 'date-fns/parse';

export interface Event {
    id: number,
    title: string,
    event_type: string,
    description: string,
    venue: string,
    capacity: number,
    event_start_time: Date,
    signup_opens_at: Date,
    signup_closes_at: Date,
    signed_up: number,
    status: string
}

export interface EventUpdate {
    capacity?: number,
    status?: string
}

export interface BoatIdAndName {
    name: string,
    id: number
}

export interface Signup {
    id: number,
    user: User,
    created_at: string,
    parking: boolean,
    parking_boat_assigned: BoatIdAndName | null
}

export interface SignupEventReservation {
    id: number,
    signup: Signup,
    roleType: string,
}

export interface EventReservation {
    id: number,
    event_id: number,
    time_slot: string,
    boat_class: string,
    boat: string | null,
    sc_reservation_id: number | null,
    reservation_date: string,
    signups: Array<SignupEventReservation>
    pre_sail_checklist_complete: boolean,
    post_sail_checklist_complete: boolean,
}

export interface EventWithDetails {
    event: Event,
    signups: Array<Signup>,
    event_reservations: Array<EventReservation>,
    waitlist: Array<Signup>
}

interface JsonEventDetails {
    event_reservations: Array<any>,
    signups: Array<any>,
    event: any,
    users: Array<any>,
    signup_event_reservations: Array<any>,
    parking_assignments: Array<any>,
    checklists: Array<any>,
}

function parseEvent(e: any): Event {
    const parseFormat = "yyyy-MM-dd'T'HH:mm:ss";

    e.event_start_time = dateFnsParse(e.event_start_time, parseFormat, new Date());
    e.signup_opens_at = dateFnsParse(e.signup_opens_at, parseFormat, new Date());
    e.signup_closes_at = dateFnsParse(e.signup_closes_at, parseFormat, new Date());

    return e as Event;
}

function parseResponse({
    event_reservations, signups, event,
    users, signup_event_reservations, parking_assignments, checklists
}: JsonEventDetails): EventWithDetails {
    const idToUser: { [key: number]: User } = {};
    users.forEach(user => {
        idToUser[user.id] = user;
    });

    const signupIdToParking: { [key: number]: BoatIdAndName } = {};
    parking_assignments.forEach(pa => {
        signupIdToParking[pa.signup_id] = { name: pa.boat_name, id: pa.boat_id };
    });

    const assignedUserIds: { [key: number]: boolean } = {};

    const idToSignup: { [key: number]: Signup } = {};
    const signupsWithUsers = signups.map(signup => {
        let s = {
            ...signup,
            user: idToUser[signup.user_id],
            parking_boat_assigned: signupIdToParking[signup.id]
        };
        delete s['user_id'];
        idToSignup[s.id] = s;
        return s
    });

    const idToPreSailChecklists: { [key: number]: boolean } = {};
    const idToPostSailChecklists: { [key: number]: boolean } = {};

    checklists.forEach(cl => {
        if (cl.checklist.report_type == 'pre-sail') {
            idToPreSailChecklists[cl.event_reservation_id] = true;
        }
        else if (cl.checklist.report_type == 'post-sail') {
            idToPostSailChecklists[cl.event_reservation_id] = true;
        }        
    });

    const idToReservations: { [key: number]: Array<SignupEventReservation> } = {};
    signup_event_reservations.forEach(ser => {
        const signup = idToSignup[ser.signup_id];
        idToReservations[ser.event_reservation_id] ||= [];
        idToReservations[ser.event_reservation_id].push({
            id: ser.id,
            roleType: ser.role_type,
            signup: signup
        });

        assignedUserIds[signup.user.id] = true;
    });

    event_reservations.forEach(res => {
        res.signups = idToReservations[res.id] || [];
        res.pre_sail_checklist_complete = idToPreSailChecklists[res.id] || false;
        res.post_sail_checklist_complete = idToPostSailChecklists[res.id] || false;
    });

    const waitlist: Array<Signup> = [];
    signupsWithUsers.forEach(signup => {
        if (!assignedUserIds[signup.user.id]) {
            waitlist.push(signup);
        }
    });

    const signupComparator = (s0: Signup, s1: Signup) => {
        if (s0.created_at < s1.created_at) {
            return -1
        } else if (s0.created_at == s1.created_at) {
            return 0;
        } else {
            return 1;
        }
    };

    signupsWithUsers.sort(signupComparator);

    return {
        event: parseEvent(event),
        event_reservations: event_reservations,
        signups: signupsWithUsers,
        waitlist: waitlist
    }
}

function eventDetails(id: any): Promise<EventWithDetails> {
    return fetch("/api/pub/events/" + id)
        .then(res => res.json())
        .then(parseResponse);
};

const boatNameToId: { [key: string]: number } = {
    "Albatross": 2,
    "Meridian": 1,
    "NakedDance": 5,
    "PocoVeliero": 15,
    "CretinHop": 20,
    "Recess": 3,
    "Riverfolk": 12,
    "Rubato": 14,
    "WindHorse": 6,
    "BlueFish": 10,
    "TwoFish": 9,
    "Troll": 16,
    "Solstice": 8,
    "Equinox": 7,
    "Calypso": 13,
    "Priorities": 11,
    "Eclipse": 4
};

function createParking(eventId: any, signupId: number, boat: string): Promise<any> {
    return fetch(
        "/api/admin/events/" + eventId +
        "/signup/" + signupId + "/parking", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json;charset=utf-8'
        },
        body: JSON.stringify({ boat_id: boatNameToId[boat] })
    });
}

function updateParking(eventId: any, signupId: number, boat: string): Promise<any> {
    return fetch(
        "/api/admin/events/" + eventId +
        "/signup/" + signupId + "/parking", {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json;charset=utf-8'
        },
        body: JSON.stringify({ boat_id: boatNameToId[boat] })
    });
}

function updateEvent(eventId: any, eventUpdate: EventUpdate): Promise<any> {
    return fetch(
        "/api/admin/events/" + eventId, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json;charset=utf-8'
        },
        body: JSON.stringify(eventUpdate)
    });
}

function deleteParking(eventId: any, signupId: number): Promise<any> {
    return fetch(
        "/api/admin/events/" + eventId +
        "/signup/" + signupId + "/parking", {
        method: 'DELETE'
    });
}

export const EventService = {
    eventDetails,
    createParking,
    deleteParking,
    updateParking,
    updateEvent,
    boatNameToId
}