import { useAuth } from './services/AuthService';
import { Card } from 'react-bootstrap';
import { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { Badge } from 'react-bootstrap';

interface EventsProps {
    admin: boolean
}

function Login() {
    const [loginError, setLoginError] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const { doLogin, onLogin } = useAuth() as any;
    const nav = useNavigate();

    const login = (e: any) => {
        e.preventDefault();
        setLoginError(null);
        setIsLoading(true);

        let password = e.target.password.value;
        
        doLogin(password).then((success: boolean) => {
            setIsLoading(false);

            if (success) {
                onLogin().then(() => {
                    nav("/");
                })
            } else {
                setLoginError("Incorrect Password");
            }
        });
    }

    return (
        <div className="login-container">
            <Card className="login">
                <Card.Body>
                    <h1 className="h3">Sail Chicago Racing</h1>
                    <p>
                        To access racing events you will need to signup for racing via the Sail Chicago website
                        or contact the racing coordinators: <a href="mailto:hinz.adam@gmail.com">Adam Hinz</a> or <a href="mailto:danielcmaloney@gmail.com">Dan Maloney</a>.
                    </p>
                    {loginError && <Badge bg="danger">{loginError}</Badge>}
                    <form onSubmit={login}>
                        <div className="mb-3">
                            <label className="form-label">Password</label>
                            <input type="password" className="form-control" id="password" />
                        </div>
                        <button type="submit" className="btn btn-primary" disabled={isLoading}>Log In</button>
                    </form>
                </Card.Body>
            </Card>
        </div>
    )
}

export default Login;