import { Modal, Button, Badge } from 'react-bootstrap';
import { useState } from 'react';
import _default from 'react-bootstrap/esm/Accordion';

interface Props {
    show: boolean,
    handleClose: any,
    eventId: number,
    signupId: number,
    handleWithdrawComplete: any,
    email: string,
}

function withdraw(eventId: number, signupId: number) {
    return fetch("/api/pub/events/" + eventId + "/signup/" + signupId, {
        method: 'DELETE'
    }).then((res: any) => {
        if (res.status == 200) {
            return {success: true}
        } else {
            return res.text().then((t: any) => {
                return {error: t}
            });
        }
    });
}

function WithdrawModal({ email, eventId, signupId, show, handleClose, handleWithdrawComplete }: Props) {
    const [error, setError] = useState(null);

    const handleWithdraw = (e: any) => {
        e.preventDefault();
        withdraw(eventId, signupId).then(res => {
            const err = (res || {})['error'];
            if (err) {
                setError(err);
            } else {
                handleClose();
                handleWithdrawComplete();
            }
        })
    }

    const localClose = () => {
        setError(null);
        handleClose();
    };

    return (
        <Modal show={show} onHide={localClose}>
            <form onSubmit={handleWithdraw}>
                <Modal.Header closeButton>
                    <Modal.Title>Remove from Event</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        {error && <div className="modal-error">{error}</div>}
                    </div>
                    <div>
                        Are you sture you want to remove "{email}" from this event?
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={localClose}>
                        Cancel
                    </Button>
                    <Button variant="primary" type="submit">
                        Remove
                    </Button>
                </Modal.Footer>
            </form>
        </Modal>
    )
}

export default WithdrawModal;