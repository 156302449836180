import { Card } from 'react-bootstrap';
import { Signup, BoatIdAndName, EventService } from '../services/EventService';

interface ParkingListProps {
    isAdmin: boolean,
    signups: Array<Signup>,
    boats: Array<string>,
    onParkingUpdated: any,
}

function BoatSelectAdmin({ boats, onBoatSelected, boat }: { boats: Array<string>, onBoatSelected: any, boat: BoatIdAndName | null }) {
    const decode = (x: string) => {
        if (x === "none") { return null } else { return x };
    };

    return (
        <select value={(boat || {}).name || "none"} onChange={(e: any) => onBoatSelected(decode(e.target.value))}>
            <option key="none" value="none">-- Boat --</option>
            {
                boats.map(boat => {
                    return (<option key={boat} value={boat}>{boat}</option>)
                })
            }
        </select>
    )
}

function BoatSelect({ boats, onBoatSelected, isAdmin, boat }: { boats: Array<string>, onBoatSelected: any, isAdmin: boolean, boat: BoatIdAndName | null }) {
    if (isAdmin) {
        return BoatSelectAdmin({ boats: boats, onBoatSelected: onBoatSelected, boat: boat })
    } else {
        return (<span>{(boat || {}).name || "Unassigned"}</span>)
    }
}

export default function ParkingList({ isAdmin, signups, boats, onParkingUpdated }: ParkingListProps) {
    const assignedParking = signups.filter(s => s.parking_boat_assigned);
    const unassignedParking = signups.filter(s => !s.parking_boat_assigned).filter(s => s.parking);

    if (signups.filter(s => s.parking).length > 0) {
        return (
            <Card className="boat">
                <Card.Body>
                    <Card.Title>Parking</Card.Title>
                    <div>
                        {assignedParking.map((signup: Signup) => (
                            <div key={signup.user.id} className="waitlist-item">
                                {signup.user.full_name} - &nbsp;
                                <BoatSelect
                                    boats={boats}
                                    onBoatSelected={(newBoat: string) => onParkingUpdated(signup.id, (signup.parking_boat_assigned || {}).name, newBoat)}
                                    isAdmin={isAdmin}
                                    boat={signup.parking_boat_assigned} />
                            </div>
                        ))}
                        {unassignedParking.map((signup: Signup) => (
                            <div key={signup.user.id} className="waitlist-item">
                                {signup.user.full_name} - &nbsp;
                                <BoatSelect
                                    boats={boats}
                                    onBoatSelected={(newBoat: string) => onParkingUpdated(signup.id, (signup.parking_boat_assigned || {}).name, newBoat)}
                                    isAdmin={isAdmin}
                                    boat={signup.parking_boat_assigned} />
                            </div>
                        ))}
                    </div>
                </Card.Body>
            </Card>
        )
    } else {
        return (<div></div>)
    }
}