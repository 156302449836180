import React from 'react'
import { useAuth } from './services/AuthService'
import { Navigate, Route } from 'react-router-dom'

const PrivateRoute = ({ children }: any) => {
    const { token } = useAuth() as any;
  
    if (!token) {
      return <Navigate to="/login" replace />;
    }
  
    return children;
  };

export default PrivateRoute