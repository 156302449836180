export interface BoatInfo {
    id: number,
    name: string,
    api_name: string,
    mooring: string,
    color_code: string | null,
}

const boatNameToInfoMap: { [key: string]: BoatInfo } = {};

// this should probably all come from the API
function addBoatInfo(id: number, api_name: string, name: string, mooring: string, color_code: string | null) {
    boatNameToInfoMap[api_name] = {
        id, name, api_name, mooring, color_code
    };
}

addBoatInfo(2, "Albatross", "Albatross", "C8", null)
addBoatInfo(1, "Meridian", "Meridian", "defunct", null)
addBoatInfo(5, "NakedDance", "Naked Dance", "W9", "yellow")
addBoatInfo(15, "PocoVeliero", "Poco Veliero", "T4", "orange")
addBoatInfo(3, "Recess", "Recess", "U5", null)
addBoatInfo(12, "Riverfolk", "Riverfolk", "B3", null)
addBoatInfo(14, "Rubato", "Rubato", "B2", null)
addBoatInfo(6, "WindHorse", "Windhorse", "W11", null)
addBoatInfo(10, "BlueFish", "Blue Fish", "X13", null)
addBoatInfo(9, "TwoFish", "Two Fish", "X11", null)
addBoatInfo(16, "Troll", "Troll", "X9", null)
addBoatInfo(8, "Solstice", "Solstice", "X12", null)
addBoatInfo(7, "Equinox", "Equinox", "X10", null)
addBoatInfo(13, "Calypso", "Calypso", "O43", null)
addBoatInfo(11, "Priorities", "Priorities", "E39", null)
addBoatInfo(4, "Eclipse", "Eclipse", "defunct", null)
addBoatInfo(20, "CretinHop", "Cretin Hop", "W12", null)

function boatNameToInfo(boat: string | null): BoatInfo | null {
    return boatNameToInfoMap[boat || ""];
}

export const Boats = {
    boatNameToInfo: boatNameToInfo
}