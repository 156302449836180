import { Button } from 'react-bootstrap';
import WithdrawModal from './WithdrawModal';
import React from 'react';
import { UserAttr } from './Boat';

interface Props {
    signups: any,
    event: any,
    handleWithdrawComplete: any,
    isAdmin: boolean,
    boats: Array<string>,
    onBoatSelected: any,
    editing: any
}

export function BoatSelect({ boats, onBoatSelected }: { boats: Array<string>, onBoatSelected: any}) {
    return (
        <select onChange={(e: any) => onBoatSelected(e.target.value)}>
            <option key="none">-- Boat --</option>
            {
                boats.map(boat => {
                    return (<option key={boat} value={boat}>{boat}</option>)
                })
            }
        </select>
    )
}

function UserList({ event, signups, handleWithdrawComplete, boats, isAdmin, onBoatSelected, editing }: Props) {
    const [signupToRemove, setSignupToRemove] = React.useState<any>({});

    return (
        <div>
            <WithdrawModal
                email={(signupToRemove.user || {}).email}
                eventId={event.id}
                signupId={signupToRemove.id}
                show={!!signupToRemove.id}
                handleClose={() => setSignupToRemove({})}
                handleWithdrawComplete={handleWithdrawComplete} />
            {signups.map((signup: any, i: number) => (
                <div key={signup.user.id} className="waitlist-item">
                    ({i + 1})                    
                    {signup.user.full_name} { isAdmin && <UserAttr user={signup.user} />}
                    &nbsp;({signup.user.email})
                    { signup.parking && <span>&nbsp;&#128663;&nbsp;</span>}
                    { editing && <Button onClick={() => setSignupToRemove(signup)} size="sm" variant="outline-warning">X</Button> }
                    {isAdmin && <BoatSelect onBoatSelected={(boat: any) => onBoatSelected(event, signup, boat)} boats={boats} />}
                </div>
            ))}
        </div>
    )
}

export default UserList;