import { Modal, Button, Badge } from 'react-bootstrap';
import { useState } from 'react';

interface Props {
    show: boolean,
    handleClose: any,
    eventId: number,
    handleSignupComplete: any,
}

function signup(eventId: number, email: string, parking: boolean) {
    return fetch("/api/pub/events/" + eventId + "/signup", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json;charset=utf-8'
        },
        body: JSON.stringify({ email: email, parking: parking })
    }).then(res => {
        if (res.status == 200) {
            return res.json();
        } else {
            return res.text().then(t => {
                return { error: t }
            });
        }
    });
}

function SignupModal({ eventId, show, handleClose, handleSignupComplete }: Props) {
    const [error, setError] = useState(null);

    const handleSignup = (e: any) => {
        e.preventDefault();
        setError(null);
        signup(eventId, e.target.email.value, e.target.parking.checked).then(res => {
            const err = (res || {})['error'];
            if (err) {
                setError(err);
            } else {
                handleClose();
                handleSignupComplete();
            }
        })
    }

    const localClose = () => {
        setError(null);
        handleClose();
    };

    return (
        <Modal show={show} onHide={localClose}>
            <form onSubmit={handleSignup}>
                <Modal.Header closeButton>
                    <Modal.Title>Event Sign Up</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        {error && <div className="modal-error">{error}</div>}
                    </div>
                    <div>
                        Email Address
                    </div>
                    <div>
                        <input type="text" className="form-control" id="email" />
                    </div>
                    <div>
                        <label>Request Parking?</label> <input type="checkbox" id="parking" />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={localClose}>
                        Close
                    </Button>
                    <Button variant="primary" type="submit">
                        Sign Up
                    </Button>
                </Modal.Footer>
            </form>
        </Modal>
    )
}

export default SignupModal;