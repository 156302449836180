import 'bootstrap/dist/css/bootstrap.min.css';

import { Navbar, Container, Card } from "react-bootstrap";
import { AuthProvider } from './services/AuthService';
import PrivateRoute from './PrivateRoute';
import React from 'react';
import Events from './Events';
import EventDetails from './EventDetails';
import Login from './Login';
import logo from './logo.png';
import { useAuth } from './services/AuthService';
import Directory from './components/Directory';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  useNavigate,
} from "react-router-dom";

function LoginInfo() {
  let { token, onLogout } = useAuth() as any;
  let nav = useNavigate();

  const logout = (e: any) => {
    e.preventDefault();
    onLogout().then(() => nav("/login"));
  }

  let admin = "";
  let loggedIn = false;

  if (token == "admin") {
    admin = " (Admin)";
    loggedIn = true;
  }

  if (token == "user") {
    loggedIn = true;
  }

  if (token == "admin") {
    return (
      <div className="login-info">
        <a href="/users">Users</a>&nbsp;|&nbsp;
        <a href="/">Events</a>&nbsp;|&nbsp;
        <a onClick={logout}>Log Out (Admin)</a>
      </div>
    )
  } else if (token == "user") {
    return (
      <div className="login-info"><a onClick={logout}>Log Out</a></div>
    )
  } else {
    return (
      <div></div>
    )
  }


}

function AdminBar() {
  let { token } = useAuth() as any;
  let userType = null;

  if (token == "admin") {
    return (
      <div>
        Admin Bar
      </div>
    )
  } else {
    return null
  }
}

function Messages() {
  let { token } = useAuth() as any;

  if (token) {
    return (
      <Container>
        <Card className="primary-details">
          <Card.Body>
            <div>
              General Racing Details are available <a href="https://docs.google.com/document/d/1iXLKrKv08PlLV0Ac3qheYxaNv_vIu6EBJHTBFxZMD0o/edit?usp=sharing">in this google doc</a>
              &nbsp;(<a target="_blank" href="https://docs.google.com/document/d/1f0kuxrHXlQR5wsUK9cMaqGnUqdP3gIh_ZLnVAYJMlWs/edit?usp=sharing">Crib Courses</a>)
            </div>
            <div>
            Current crib readings: <a target="_blank" href="https://www.glerl.noaa.gov/metdata/chi/">https://www.glerl.noaa.gov/metdata/chi/</a>
            </div>
          </Card.Body>
        </Card>
      </Container>
    )
  } else {
    return (<span></span>);
  }
}

function App() {
  return (
    <Router>
      <AuthProvider>
        <header>
          <Navbar bg="light" expand="lg">
            <Container>
              <Navbar.Brand href="/">
                <img src={logo} />
                Sail Chicago Racing
              </Navbar.Brand>
              <LoginInfo />
            </Container>
          </Navbar>
        </header>
        <Messages />
        <Container>
          <div className="App">
            <Routes>
              <Route path="/login" element={
                <Login />
              } />
              <Route path="/users" element={
                <PrivateRoute adminOnly={true}>
                  <Directory />
                </PrivateRoute>
              } />
              <Route path="/" element={
                <PrivateRoute adminOnly={false}>
                  <Events admin={true} />
                </PrivateRoute>} />
              <Route path="/events/:id" element={
                <PrivateRoute adminOnly={false}>
                  <EventDetails admin={true} />
                </PrivateRoute>} />
            </Routes>

          </div>
        </Container>
      </AuthProvider>
    </Router>
  );
}


export default App;
