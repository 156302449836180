import sailing from './sailing.png';
import { Link } from 'react-router-dom';
import { Card, Button, Badge } from 'react-bootstrap';
import { Detail, Summary } from './WednesdayEventSummary';
import dateFnsFormat from 'date-fns/format';
import dateFnsParse from 'date-fns/parse';

export interface IEvent {
    id: number,
    title: string,
    event_type: string,
    description: string,
    venue: string,
    capacity: number,
    event_start_time: Date,
    signup_opens_at: Date,
    signup_closes_at: Date,
    signed_up: number,
    status: string
}

type Props = {
    event: IEvent,
    children: any,
    mode: string,
    isAdmin: boolean,
    onCapacityUpdated: (id: number, capacity: number) => void
}

export function parseEvent(e: any): IEvent {
    const parseFormat = "yyyy-MM-dd'T'HH:mm:ss";

    e.event_start_time = dateFnsParse(e.event_start_time, parseFormat, new Date());
    e.signup_opens_at = dateFnsParse(e.signup_opens_at, parseFormat, new Date());
    e.signup_closes_at = dateFnsParse(e.signup_closes_at, parseFormat, new Date());

    return e as IEvent;
}

function Venue({ venue }: { venue: string }) {
    let venueName = venue;
    let link = null;
    let zip = 60601;
    let addr = "Chicago";

    if (venue == "Columbia") {
        venueName = "Columbia Yacht Club";
        link = "https://goo.gl/maps/xWWkKWtsQADewstv6";
        addr = "111 N Lake Shore Dr";
        zip = 60601;
    } else if (venue == "Monroe") {
        venueName = "Monroe Tender"
        link = "https://goo.gl/maps/4oXWgtEX3taoEX9NA";
        addr = "400 E Monroe St";
        zip = 60603;
    }

    return (
        <div className="venue">
            <div className="venue-name">
                <span>{venueName} </span>
                {link && <a href={link} target="_blank">(Map)</a>}
            </div>
            <div className="venue-address">
                {addr} <br />
                Chicago, IL {zip}
            </div>
        </div>
    )
}

interface CapacityProps {
    capacity: number,
    mode: string,
    isAdmin: boolean,
    onCapacityUpdated: (capacity: number) => void
}

function Capacity({ capacity, mode, isAdmin, onCapacityUpdated }: CapacityProps) {
    if (isAdmin && mode === 'full') {
        const elements = [];

        for (let i = 0; i < 45; i++) {
            elements.push(<option key={i} value={i}>{i}</option>);
        }

        return (
            <select value={capacity} onChange={(e: any) => onCapacityUpdated(parseInt(e.target.value))}>
                {elements}
            </select>
        )
    } else {
        return (
            <span>{capacity}</span>
        )
    }
}

function Event({ event, mode, isAdmin, onCapacityUpdated, children }: Props) {
    const img = "/event_" + (event.id % 11) + ".jpeg";
    const classes = "event event-" + mode;
    const subheadFormat = "iiii LLLL do yyyy";
    const dateTimeForamt = "iiii LLLL dd yyyy 'at' HH:mm";

    return (
        <Card className={classes}>
            <Link to={"/events/" + event.id}>
                <Card.Img variant="top" src={img} />
            </Link>
            <Card.Body>
                <Card.Title>{event.title}</Card.Title>
                <Card.Subtitle>{dateFnsFormat(event.event_start_time, subheadFormat)}</Card.Subtitle>
                <div className="card-text">
                    {mode == "full" && <Detail eventType={event.event_type} desc={event.description} />}
                    {mode == "summary" && <Summary eventType={event.event_type} desc={event.description} />}
                </div>
                <div className="date-and-time">
                    <div className="dates">
                        <div className="date-pair">
                            <div className="date-type">
                                <Badge bg="secondary">Sign Up Opens At</Badge>

                            </div>
                            <div className="date-date">{dateFnsFormat(event.signup_opens_at, dateTimeForamt)}</div>
                        </div>
                        <div className="date-pair">
                            <div className="date-type">
                                <Badge bg="secondary">Sign Up Closes At</Badge>

                            </div>
                            <div className="date-date">{dateFnsFormat(event.signup_closes_at, dateTimeForamt)}</div>
                        </div>
                        <div className="date-pair">
                            <div className="date-type">
                                <Badge bg="secondary">Event Start Time</Badge>

                            </div>
                            <div className="date-date">{dateFnsFormat(event.event_start_time, dateTimeForamt)}</div>
                        </div>
                    </div>
                    <div className="time-and-venue">
                        <Venue venue={event.venue} />
                    </div>
                </div>
            </Card.Body>
            <Card.Footer>
                {children}
                <div>Seats: {event.signed_up} /&nbsp;
                    <Capacity
                        mode={mode}
                        capacity={event.capacity}
                        isAdmin={isAdmin}
                        onCapacityUpdated={(cap) => onCapacityUpdated(event.id, cap)} /></div>
            </Card.Footer>
        </Card>
    )
}

export default Event;