import { Table, Card, Button } from 'react-bootstrap';
import { EventReservation, SignupEventReservation } from './services/EventService';
import { BoatSelect } from './UserList';
import { Boats } from './data/Boats';

interface BoatProps {
    reservation: EventReservation,
    isAdmin: boolean,
    onDeleteEventRes: any,
    onRoleSelected: any,
    boats: Array<any>,
    onBoatSelected: any,
}

interface CrewRowProps {
    signup: SignupEventReservation,
    isAdmin: boolean,
    onDeleteEventRes: any,
    onRoleSelected: any,
    boats: Array<any>,
    onBoatSelected: any,
}

export function UserAttr({ user }: any) {
    return (
        <span>
            (
            {user.racing_skipper && "R"}
            {user.skipper && "S"}
            {user.foredeck_cert && "F"}
            )
        </span>
    )
}

function RoleType({ onRoleSelected }: any) {
    return (
        <select onChange={onRoleSelected}>
            <option key="none">-- Change Role --</option>
            <option key="skipper">Skipper</option>
            <option key="foredeck">Foredeck</option>
            <option key="crew">Crew</option>
        </select>
    )
}

function CrewRow({ signup, isAdmin, onDeleteEventRes, onRoleSelected, boats, onBoatSelected }: CrewRowProps) {
    return (
        <tr key={signup.id}>
            <td>{signup.roleType}</td>
            <td>
                {isAdmin && <Button onClick={() => onDeleteEventRes(signup)} size="sm" variant="warning">X</Button>}
                {signup.signup.user.full_name} {isAdmin && <UserAttr user={signup.signup.user} />}<br />
                {signup.signup.user.email}<br />
                {(signup.roleType === "Skipper") &&
                    <span>{signup.signup.user.phone} <br /></span>
                }
                {isAdmin && <div><RoleType onRoleSelected={(e: any) => onRoleSelected(signup, e.target.value)} /></div>}
                {isAdmin && <div><BoatSelect onBoatSelected={(boat: any) => onBoatSelected(signup, boat)} boats={boats} /></div>}
                {isAdmin && <br />}
            </td>
        </tr>
    )
}

interface MissingProps {
    role: string
}

function Missing({ role }: MissingProps) {
    return (
        <tr key={role} className="missing"><td>{role}</td><td className="missing-text">(missing)</td></tr>
    )
}

interface ChecklistProps {
    preSailChecklistComplete: boolean,
    postSailChecklistComplete: boolean,
    skipperName: string,
    foredeckName: string,
    boatName: string,
}

function buildPostSailChecklistUrl(_name: string, _boat: string) {
    return "https://airtable.com/shrGrsIHdYgFgT8qN";
}

function buildPreSailChecklistUrl(_name: string, _boat: string) {
    return "https://airtable.com/shrt2tLABP4rpSVhT";
}

function Checklists({
    preSailChecklistComplete,
    postSailChecklistComplete,
    skipperName,
    boatName,
}: ChecklistProps) {
    return (
        <div>
            <Table striped bordered hover>
                <tbody>
                    <tr>
                        <td><a target="_blank" href={buildPreSailChecklistUrl(skipperName, boatName)}>Pre Sail</a></td>
                        <td><a target="_blank" href={buildPostSailChecklistUrl(skipperName, boatName)}>Post Sail</a></td>
                    </tr>
                    {/*
                    <tr>
                        <td>{preSailChecklistComplete ? "Complete" : "Not Done"}</td>
                        <td>{postSailChecklistComplete ? "Complete" : "Not Done"}</td>
                    </tr>
                    */}
                </tbody>
            </Table>
        </div>
    )
}

function Boat({ reservation, isAdmin, onDeleteEventRes, onRoleSelected, boats, onBoatSelected }: BoatProps) {

    // All boats should have at least one (and only one) skipper and foredeck
    // Not enforced though
    // - should make sure we show all users
    // - if missing foredeck/skipper add a special "missing box"
    let skippers = reservation.signups.filter(signup => signup.roleType === "Skipper");
    let foredeck = reservation.signups.filter(signup => signup.roleType === "Foredeck");

    const boatInfo = Boats.boatNameToInfo(reservation.boat);
    let boatName = "Unassigned";
    if (boatInfo) {
        boatName = boatInfo.name + " (" + boatInfo.mooring + ")";
    }

    let skipperName = "";

    if (skippers.length > 0) {
        skipperName = skippers[0].signup.user.full_name;
    }

    let foredeckName = "";

    if (foredeck.length > 0) {
        foredeckName = foredeck[0].signup.user.full_name;
    }

    return (
        <Card className="boat">
            <Card.Body>
                <Card.Title>{boatName}</Card.Title>
                <div>
                    {boatInfo &&
                        <Checklists
                            preSailChecklistComplete={reservation.pre_sail_checklist_complete}
                            postSailChecklistComplete={reservation.post_sail_checklist_complete}
                            skipperName={skipperName}
                            foredeckName={foredeckName}
                            boatName={boatInfo ? boatInfo.name : "No Boat"}
                        />}


                    <Table striped bordered hover>
                        <tbody>
                            {skippers.map(signup => (
                                <CrewRow
                                    boats={boats}
                                    onBoatSelected={(s: any, boat: string) => onBoatSelected(reservation, s, boat)}
                                    key={signup.id}
                                    signup={signup}
                                    isAdmin={isAdmin}
                                    onRoleSelected={(s: any, role: string) => onRoleSelected(reservation, s, role)}
                                    onDeleteEventRes={(s: any) => onDeleteEventRes(reservation, s)} />
                            ))}
                            {skippers.length === 0 && <Missing role="Skipper" />}
                            {foredeck.map(signup => (
                                <CrewRow
                                    boats={boats}
                                    onBoatSelected={(s: any, boat: string) => onBoatSelected(reservation, s, boat)}
                                    key={signup.id}
                                    signup={signup}
                                    isAdmin={isAdmin}
                                    onRoleSelected={(s: any, role: string) => onRoleSelected(reservation, s, role)}
                                    onDeleteEventRes={(s: any) => onDeleteEventRes(reservation, s)} />
                            ))}
                            {foredeck.length === 0 && <Missing role="Foredeck" />}
                            {reservation.signups.filter(s => s.roleType === 'Crew').map(signup => (
                                <CrewRow
                                    boats={boats}
                                    onBoatSelected={(s: any, boat: string) => onBoatSelected(reservation, s, boat)}
                                    key={signup.id}
                                    signup={signup}
                                    isAdmin={isAdmin}
                                    onRoleSelected={(s: any, role: string) => onRoleSelected(reservation, s, role)}
                                    onDeleteEventRes={(s: any) => onDeleteEventRes(reservation, s)} />
                            ))}
                        </tbody>
                    </Table>
                </div>
            </Card.Body>
        </Card>
    )
}

export default Boat;